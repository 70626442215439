<template>
  <div class="container-fluid">
    <div class="jumbotron jumbobin">
      <p><img class="arrondi" src="../assets/img/CGB.png"></p>
      <p><router-link :to="{ name: 'login' }" class="btn btn-lg btn-primary" role="button">Vous Connecter
          ...</router-link></p>
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import 'src/assets/scss/design.scss';

.jumbobin {
  background: url('../assets/img/cgb.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
}

.btn-primary {
  background-color: $back-color;
}

.arrondi {
  border-radius: 15px;
}
</style>
